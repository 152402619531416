import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import { ScrollPercentage } from 'react-scroll-percentage';
import quotationMarksIcon from 'images/PatientStoriesAndResources/quotation_marks_icon.svg';
import documentIcon from 'images/PatientStoriesAndResources/document_icon.svg';
import globeIcon from 'images/PatientStoriesAndResources/globe_icon.svg';
import {
  LinkContents,
  SubSectionH2,
  SubSectionH3,
  SectionTitle,
  LargeImg,
  LineBreak,
  Paragraph,
  Note,
  SEO,
  VideoPlayer
} from 'components';
import qAndABookletPDF from 'static/Q&A_booklet.pdf';
import familyHealthTreePDF from 'static/family_health_tree.pdf';
import yourFamilyAndHATTRAmyloidosisPDF from 'static/your_family_and_hATTR_amyloidosis.pdf';
import yourHealthAndHATTRAmyloidosisPDF from 'static/your_health_and_hATTR_amyloidosis.pdf';

const PatientStoriesAndResources = ({
  checkIfSectionIsInView,
  data,
  location,
  populateAsideWithSectionTitlesFromCurrentPage,
  scrollToAnchor,
  updateActiveAnchor,
  viewportWidth
}) => {
  const [playingVideo, setPlayingVideo] = useState(null);

  useEffect(() => {
    if (viewportWidth >= 768) {
      populateAsideWithSectionTitlesFromCurrentPage();
    }
  }, [viewportWidth]);

  return (
    <section>
      <SEO
        pageSpecificTitle="Patient Stories And Resources"
        pageSpecificDescription="The story of Hereditary ATTR (hATTR) Amyloidosis in the Northwest of Ireland."
        pathname={location.pathname}
      />
      <SubSectionH2 styles={{ marginTop: '8.5rem' }}>
        Disease Roots in the&nbsp;{viewportWidth >= 375 && <br />}Northwest of Ireland
      </SubSectionH2>
      <Paragraph>
        The T80A mutation (formerly known as T60A) associated with hATTR amyloidosis is thought to
        originate from a 15 mile stretch of coastline in Donegal.
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>1</sup>
        </Link>
      </Paragraph>
      <ScrollPercentage
        as="div"
        onChange={(percentage) =>
          checkIfSectionIsInView(percentage, '#james-and-pat-coyles-story')
        }>
        <SectionTitle id="james-and-pat-coyles-story" styles={{ margin: '2.5em 0 1.15em' }}>
          James and Pat Coyle&apos;s Story
        </SectionTitle>
      </ScrollPercentage>
      <VideoPlayer
        vimeoCode="653686404"
        paddingTop="56.097560975609756%"
        title="James and Pat Coyle's Story"
        playingVideo={playingVideo}
        setPlayingVideo={setPlayingVideo}
        thumbnail={data.jamesStoryVideoThumbnail}
      />
      <SubSectionH3>This is James and Pat Coyle&apos;s story.</SubSectionH3>
      <Paragraph>
        James Coyle and his first cousin Pat, come from the western most tip of Ireland, Belmullet,
        Co Mayo, a remote area on the shores of the Atlantic Ocean.
      </Paragraph>
      <LineBreak />
      <Paragraph>
        Generations of their families have grown up here, have helped build the community, are
        educated here, play sport, raise families, and continue to farm the land passed down from
        their fathers and their fathers before.
      </Paragraph>
      <Paragraph>
        <b>
          Both their fathers, Packie and Seamus Coyle, have died of the genetically inherited
          disease, hATTR Amyloidosis
        </b>
      </Paragraph>
      <LineBreak />
      <Paragraph>
        James and Pat are 2 cousins living in the West of Ireland with their young families.
      </Paragraph>
      <Paragraph>
        In their extended family line, along with a history of emigration, there is a history of
        hATTR Amyloidosis.{' '}
        <b>
          James and Pat&apos;s grandfather, some uncles, aunts and cousins have passed away from the
          illness or carry the gene.
        </b>
      </Paragraph>
      <LineBreak />
      <Paragraph>
        However, with increasing research into hereditary illnesses, greater awareness and support
        infrastructure and treatment options,{' '}
        <b>
          their story is not only about the history of illness in their family, it is as much about
          the present and the future, and the steps they can take to manage the disease, should they
          go on to develop hATTR amyloidosis.
        </b>
      </Paragraph>
      <SmallerQuoteWithImage>
        <QuoteMarks>
          <img src={quotationMarksIcon} alt="quotation marks" />
        </QuoteMarks>
        <p>
          “They talk about it being a rare disease, but it&apos;s not a rare disease when it&apos;s
          in your family.”
        </p>
        <span>- James Coyle</span>
        <JamesStanding
          fluid={data.jamesStanding.childImageSharp.fluid}
          imgStyle={{ objectFit: 'contain' }}
          alt="man standing with hands in his pockets"
        />
      </SmallerQuoteWithImage>
      <LineBreak customHeight="3.55em" />
      <LargeImg
        sources={[data.mayoAerialView.childImageSharp.fluid]}
        alt={data.mayoAerialView.name}
        caption="Image - Aerial view, Co. Mayo"
      />
      <LineBreak customHeight="3.4em" />
      <SubSectionH3>Getting Tested for the hATTR Amyloidosis Gene</SubSectionH3>
      <Paragraph>
        James and Pat talk about the process of getting tested for the gene. James visited his local
        GP Belmullet and got blood samples taken. These were sent over to the National Amyloidosis
        Center (The NAC) at The Royal Free Hospital in London. Within 3-4 weeks the results were
        returned to James&apos; local GP and the results were shared with him. Pat is considering
        whether to take the genetic test himself, and if he decides to take the test, he can avail
        of genetic testing through his local Specialist.
      </Paragraph>
      <LineBreak />
      <a
        href="https://www.ucl.ac.uk/amyloidosis/national-amyloidosis-centre/molecular-genetic-testing/request-genetic-test"
        target="__blank"
        style={{ textDecoration: 'none' }}
        id="request-genetic-test-link">
        <LinkContents
          backgroundColor="--amy-lightest-blue-transparent"
          text={
            viewportWidth >= 1000
              ? '<p><strong>Here is the link</strong> to give to your local Specialist if requesting a Genetic Test from the NAC, as discussed by James</p>'
              : '<p><strong>Link</strong> to give to your local Specialist if requesting a Genetic Test from the NAC</p>'
          }
          fontSize={viewportWidth < 450 ? '0.875rem' : viewportWidth < 600 ? '1rem' : '1.25rem'}
          fontWeight="400"
          height="4.325em"
          iconSVG={globeIcon}
          iconSize="1.675em"
          iconSVGAlt="document"
          padding="0.5rem 0"
          borderRadius="4px 8px 8px 4px"
          border="1px solid var(--amy-dark-blue)"
          iconBackgroundColor="--amy-dark-blue"
          iconBorderRadius="0px 4px 4px 0px"
          textAlign={viewportWidth < 600 && 'left'}
        />
      </a>
      <LineBreak customHeight="3.4em" />
      <Quote>
        <QuoteMarks>
          <img src={quotationMarksIcon} alt="quotation marks" />
        </QuoteMarks>
        <p style={{ paddingBottom: '1rem' }}>
          “I found that there was a simple enough process to getting the genetic screening test
          done, within 3-4 weeks I had my results back.”
        </p>
        <span>– James Coyle</span>
      </Quote>
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#rosalines-story')}>
        <SectionTitle id="rosalines-story">Rosaline&apos;s Story</SectionTitle>
      </ScrollPercentage>
       <VideoPlayer
        vimeoCode="454300762"
        paddingTop="56.097560975609756%"
        title="Rosaline's Story"
        playingVideo={playingVideo}
        setPlayingVideo={setPlayingVideo}
        thumbnail={data.rosalinesStoryVideoThumbnail}
      />
      <SubSectionH3>This is Rosaline Callaghan&apos;s story.</SubSectionH3>
      <Paragraph>
        Rosaline Callaghan is a retired barrister from Derry.{' '}
        <b>
          Members of Rosaline&apos;s family, all from Derry and Donegal, were some of the first to
          be diagnosed with hATTR amyloidosis caused by the T80A mutation
        </b>
        .
        <Link
          to="/"
          onClick={(e) => {
            e.preventDefault();
            updateActiveAnchor(null);
            scrollToAnchor('references');
          }}>
          <sup>1</sup>
        </Link>{' '}
        Rosaline had lost an aunt and other family members, all from Burt, a small village in Co
        Donegal, to the condition. When her father Hugh Charles died from the condition in 1997, she
        knew
        <b> the condition was &quot;coming for her&quot;</b>.
      </Paragraph>
      <LineBreak />
      <Paragraph>
        She<b> quit her job as a barrister to travel the world</b>, but when she began to experience
        &quot;sudden&quot; symptoms she knew what it was. Rosaline&apos;s diagnosis was confirmed at
        the Royal Free Hospital in London, the home of the National Amyloidosis Centre (NAC).
      </Paragraph>
      <LineBreak />
      <Paragraph>
        Thanks to the research and dedication of staff at the NAC,
        <b>
          {' '}
          Rosaline has been diagnosed with hATTR amyloidosis but feels that there is hope for
          patients like her
        </b>
        .
      </Paragraph>
      <LineBreak customHeight="3.55em" />
      <LargeImg
        sources={[data.royalFreeHospital.childImageSharp.fluid]}
        alt={data.royalFreeHospital.name}
        caption="Image - The Royal Free Hospital, London"
      />
      <QuoteWithImage>
        <QuoteMarks>
          <img src={quotationMarksIcon} alt="quotation marks" />
        </QuoteMarks>
        <p>
          My father had a discussion with me, and said &quot;Rosaline, they&apos;re saying that this
          is hereditary, I think this could have implications for us&quot;.
        </p>
        <RosalineSitting
          fluid={data.rosalineSitting.childImageSharp.fluid}
          alt="woman sitting with hands crossed"
        />
      </QuoteWithImage>
      <SubSectionH3>Be aware of your family history</SubSectionH3>
      <Paragraph>
        Rosaline is urging doctors and patients to consider hATTR amyloidosis when investigating or
        diagnosing conditions. She is also asking everyone to be aware of their family history.
      </Paragraph>
      <LineBreak customHeight="5.05em" />
      <Quote>
        <QuoteMarks>
          <img src={quotationMarksIcon} alt="quotation marks" />
        </QuoteMarks>
        <p>
          When my father was in hospital in Belfast, experts came from all over the world to see him
          and learn from him. One day, they were removing nerves from his foot and he said:{' '}
          <b>
            &quot;I know you can do nothing for me, but please take all you can to help the ones
            coming after.&quot;
          </b>{' '}
          That makes me weep, but because of him and everyone who we have loved and lost,{' '}
          <b>there is hope for the people to come now.</b>
        </p>
      </Quote>
      <LineBreak customHeight="3.55em" />
      <LargeImg
        sources={[data.burtCastle.childImageSharp.fluid]}
        alt={data.burtCastle.name}
        caption="Image - Burt Castle, Donegal"
      />
      <LineBreak customHeight="3.4em" />
      <SubSectionH3>Early diagnosis and treatment are vital</SubSectionH3>
      <Paragraph>
        In October 2018, Rosaline developed bilateral carpal tunnel syndrome and amyloid deposits
        were found in her heart. Her other symptoms included difficulty with walking, numbness and
        pains that felt like electric shocks in her legs.
      </Paragraph>
      <LineBreak />
      <Paragraph>
        Rosaline has urged individuals to make themselves aware of the symptoms associated with
        hATTR amyloidosis. As the symptoms of hATTR amyloidosis are likely to continue to progress
        over time, early diagnosis and treatment are important.
      </Paragraph>
      <LineBreak customHeight="4.9em" />
      <Note fontSize="1.25em" fontWeight="700" marginTop="6.55em">
        <p>
          Rosaline presented to the Royal Free Hospital a couple of years in advance of most others.
          This was because she was familiar with the symptoms of hATTR amyloidosis.
        </p>
        <br />
        <p>
          Due to the <b>range of possible symptoms</b>, experts predict that people are going to
          their doctor for three or four years before a correct diagnosis of hATTR amyloidosis is
          given.{' '}
          <Link
            style={{ color: '#000' }}
            to="/"
            onClick={(e) => {
              e.preventDefault();
              updateActiveAnchor(null);
              scrollToAnchor('references');
            }}>
            <sup style={{ fontSize: '1rem', lineHeight: '1em', fontWeight: 'normal' }}>4</sup>
          </Link>{' '}
          <b>Donegal and Derry ancestry may have an impact in relation to hATTR amyloidosis.</b>{' '}
        </p>
      </Note>
      <LineBreak customHeight="-1.05em" />
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#other-patient-stories')}>
        <SectionTitle id="other-patient-stories">Other patient stories</SectionTitle>
      </ScrollPercentage>
      <SubSectionH2>Hear From Other People Living With hATTR Amyloidosis</SubSectionH2>
      <OtherPatientStoriesWrapper>
        <div style={{ marginBottom: '1.25em' }}>
          <VideoPlayer
            vimeoCode="766160634"
            paddingTop="56.097560975609756%"
            title="David's Story"
            playingVideo={playingVideo}
            setPlayingVideo={setPlayingVideo}
            thumbnail={data.davidsStoryVideoThumbnail}
          />
          <LinkContents
            text="<p style='margin-right:-3em'}}>David's Story</p>"
            fontSize={viewportWidth < 450 ? '1rem' : viewportWidth < 600 ? '1.325rem' : '1.625rem'}
            height="3em"
            centerText
          />
        </div>

        <a
          href="https://www.hattrbridge.eu/amyloidosis-resources-and-support/video-library"
          target="__blank">
          <LinkContents
            text={
              viewportWidth >= 1000
                ? '<p>Click here to view more patient stories</p>'
                : '<p>View more patient stories</p>'
            }
            fontSize={viewportWidth < 450 ? '1rem' : viewportWidth < 600 ? '1.325rem' : '1.625rem'}
            height="3.846em"
            iconSVG={globeIcon}
            iconSize="1.6em"
            iconSVGAlt="document"
            iconBackgroundColor="--amy-lightest-blue"
            textAlign={viewportWidth < 600 && 'left'}
          />
        </a>
      </OtherPatientStoriesWrapper>
      <ScrollPercentage
        as="div"
        onChange={(percentage) => checkIfSectionIsInView(percentage, '#resources')}>
        <SectionTitle id="resources">Resources</SectionTitle>
      </ScrollPercentage>
      <Resources>
        <a href={qAndABookletPDF} target="__blank" id="q-and-a-booklet-pdf">
          <LinkContents
            text={
              viewportWidth >= 1000
                ? '<p>Q & A Booklet</p><p>Personal experiences, feelings and hopes from patients and carers living with hATTR amyloidosis.</p>'
                : '<p>Q & A Booklet</p>'
            }
            fontSize={viewportWidth < 450 ? '1rem' : viewportWidth < 600 ? '1.325rem' : '1.625rem'}
            height="3.846em"
            iconSVG={documentIcon}
            iconSize="1.6em"
            iconSVGAlt="document"
            iconBackgroundColor="--amy-blue"
            textAlign={viewportWidth < 600 && 'left'}
          />
        </a>
        <a href={yourHealthAndHATTRAmyloidosisPDF} target="__blank" id="your-health-pdf">
          <LinkContents
            text={
              viewportWidth >= 1000
                ? '<p>Your Health And hATTR Amyloidosis</p><p>An introduction to hATTR amyloidosis.</p>'
                : '<p>Your Health And hATTR Amyloidosis</p>'
            }
            fontSize={viewportWidth < 450 ? '1rem' : viewportWidth < 600 ? '1.325rem' : '1.625rem'}
            height="3.846em"
            iconSVG={documentIcon}
            iconSize="1.6em"
            iconSVGAlt="document"
            iconBackgroundColor="--amy-blue"
            textAlign={viewportWidth < 600 && 'left'}
          />
        </a>
        <a href={yourFamilyAndHATTRAmyloidosisPDF} target="__blank" id="your-family-pdf">
          <LinkContents
            text={
              viewportWidth >= 1000
                ? '<p>Your Family And hATTR Amyloidosis</p><p>A leaflet to help patients consider what’s right for them and their family. It will also help patients address how their loved ones may wish to support them.</p>'
                : '<p>Your Family And hATTR Amyloidosis</p>'
            }
            fontSize={viewportWidth < 450 ? '1rem' : viewportWidth < 600 ? '1.325rem' : '1.625rem'}
            height="3.846em"
            iconSVG={documentIcon}
            iconSize="1.6em"
            iconSVGAlt="document"
            iconBackgroundColor="--amy-blue"
            textAlign={viewportWidth < 600 && 'left'}
          />
        </a>
        <a href={familyHealthTreePDF} target="__blank" id="family-health-tree-pdf">
          <LinkContents
            text={
              viewportWidth >= 1000
                ? '<p>Family Health Tree</p><p>A tool to help patients map their family’s history of hATTR amyloidosis.</p>'
                : '<p>Family Health Tree</p>'
            }
            fontSize={viewportWidth < 450 ? '1rem' : viewportWidth < 600 ? '1.325rem' : '1.625rem'}
            height="3.846em"
            iconSVG={documentIcon}
            iconSize="1.6em"
            iconSVGAlt="document"
            iconBackgroundColor="--amy-blue"
            textAlign={viewportWidth < 600 && 'left'}
          />
        </a>
        <a href="https://www.hattrbridge.eu/" target="__blank" id="the-bridge-link">
          <LinkContents
            text={
              viewportWidth >= 1000
                ? "<p>'The Bridge' Website</p><p>THE BRIDGE® is a programme designed to raise awareness of hATTR amyloidosis and promote education on the condition for patients and their families.</p>"
                : "<p>'The Bridge' Website</p>"
            }
            fontSize={viewportWidth < 450 ? '1rem' : viewportWidth < 600 ? '1.325rem' : '1.625rem'}
            height="3.846em"
            iconSVG={globeIcon}
            iconSize="1.462em"
            iconSVGAlt="document"
            iconBackgroundColor="--amy-lightest-blue"
            textAlign={viewportWidth < 600 && 'left'}
          />
        </a>
      </Resources>
      <LineBreak customHeight="-0.95em" />
      <Footnotes id="references">
        <span>References</span>
        <br />
        1. Gillmore JD et al. Adv Ther. 2022;39(6):2292-2301
        <br />
        2. Reilly MM, Staunton H, Harding AE. Familial amyloid polyneuropathy (TTR Ala 60) in North
        West Ireland: a clinical, genetic, and epidemiological study. J Neurol Neurosurg Psychiatry.
        1995; 59:45–49.
        <br />
        3. Sattianayagam PT, Hahn AF, Whelan CJ, et al. Cardiac phenotype and clinical outcome of
        familial amyloid polyneuropathy associated with transthyretin alanine 60 variant. Eur Heart
        J. 2012; 33:1120–1127.
        <br />
        4. Adams D, Ando Y, Beirão JM, et al. Expert consensus recommendations to improve diagnosis
        of ATTR amyloidosis with polyneuropathy [published online ahead of print, 2020 Jan 6]. J
        Neurol. 2020;10.1007/s00415-019-09688-0.
        <br />
        <br />
        <span>Abbreviations</span>
        <br />
        ATTR: Transthyretin amyloidosis
        <br />
        hATTR: hereditary ATTR (hATTR)
        <br />
        MRI: magnetic resonance imaging
      </Footnotes>
    </section>
  );
};

const OtherPatientStoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.875em;

  > div,
  > a {
    border-radius: 0 0 0.25em 0;
    overflow: hidden;
    text-decoration: none;
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0 auto 2em;
  }

  @media screen and (min-width: 62.5em) {
    > div,
    > a {
      margin: 0;
    }
  }
`;

const Resources = styled.div`
  > a {
    border-radius: 0.25em;
    display: block;
    margin-bottom: 1.25em;
    overflow: hidden;
    text-decoration: none;
  }
`;

const FurtherResources = styled.div`
  background-color: var(--amy-light-grey);
  font-size: 1.25rem;
  line-height: 2.3em;
  padding: 1.5em 1em;

  ul {
    list-style-position: inside;
  }

  a {
    color: #000;
  }
`;

const Quote = styled.div`
  background: var(--amy-lightest-blue-transparent);
  border-radius: 0.25em;
  margin: 0 auto;
  padding: calc(1.25em + 1.5vw) calc(1.75em + 1.5vw);
  position: relative;

  p {
    font-size: 1.25rem;
    line-height: 1.583em;
  }
  span {
    color: var(--amy-blue);
    font-size: 1rem;
    font-weight: 700;
  }

  @media screen and (min-width: 53.125em) {
    padding: 2.825em 4.05em;

    p {
      font-size: 1.5rem;
    }
  }
`;

const QuoteWithImage = styled(Quote)`
  height: 37.5em;
  padding: 2em;
  margin-top: 5em;

  @media screen and (min-width: 43.75em) {
    height: unset;
    margin: 10.25em auto 0;

    p {
      max-width: 47%;
    }
  }

  @media screen and (min-width: 53.125em) {
    margin: 13.75em auto 0;
    padding: 3.8em 0 3.65em 3.938em;

    p {
      max-width: 40%;
    }
  }
`;

const SmallerQuoteWithImage = styled(Quote)`
  height: 37.5em;
  padding: 2em;
  margin-top: 5em;
  span {
    color: var(--amy-light-blue);
    font-size: 1rem;
    font-weight: 700;
  }
  @media screen and (min-width: 43.75em) {
    height: 100%;
    margin: 5.25em auto 0;

    p {
      max-width: 47%;
      padding-bottom: 0.75rem;
    }
  }

  @media screen and (min-width: 53.125em) {
    margin: 8.75em auto 0;
    padding: 1.6em 0 1.6em 3.938em;

    p {
      max-width: 67.5%;
      padding-bottom: 1rem;
    }
  }
`;

const QuoteMarks = styled.div`
  align-items: center;
  background-color: var(--amy-light-blue);
  border-radius: 50%;
  display: flex;
  height: 2.75em;
  justify-content: center;
  left: -1.375em;
  position: absolute;
  top: -1.375em;
  width: 2.75em;

  img {
    width: 60%;
  }

  @media screen and (min-width: 56.25em) {
    height: 3em;
    left: -1.5em;
    top: -1.5em;
    width: 3em;
  }
`;

const RosalineSitting = styled(Img)`
  bottom: 0;
  position: absolute !important;
  right: 0;
  width: 100%;

  @media screen and (min-width: 27.5em) {
    width: 70%;
  }

  @media screen and (min-width: 43.75em) {
    width: 15em;
  }

  @media screen and (min-width: 53.125em) {
    width: 20em;
  }
`;

const JamesStanding = styled(Img)`
  bottom: 0;
  position: absolute !important;
  right: 0;
  width: 100%;
  max-height: 25em;
  @media screen and (min-width: 27.5em) {
    width: 80%;
  }

  @media screen and (min-width: 31.875em) {
    height: unset;
    width: 90%;
  }

  @media screen and (min-width: 43.75em) {
    width: 12em;
    right: 2em;
  }

  @media screen and (min-width: 53.125em) {
    width: 12em;
    right: 2em;
  }
`;

const Footnotes = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  margin-top: 8.167em;

  span {
    text-transform: uppercase;
    text-decoration: underline;
  }
`;

export const PatientStoriesAndResourcesQuery = graphql`
  {
    royalFreeHospital: file(
      relativePath: { eq: "PatientStoriesAndResources/royal_free_hospital.png" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    burtCastle: file(relativePath: { eq: "PatientStoriesAndResources/burt_castle.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 460, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mayoAerialView: file(relativePath: { eq: "PatientStoriesAndResources/mayo_aerial_view.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 460, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    rosalineSitting: file(relativePath: { eq: "PatientStoriesAndResources/rosaline_sitting.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 467, maxHeight: 554, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jamesStanding: file(relativePath: { eq: "PatientStoriesAndResources/james_standing.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 340, maxHeight: 440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ladyStandingOnBridge: file(
      relativePath: { eq: "PatientStoriesAndResources/lady_standing_on_bridge.jpg" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 225, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rosalinesStoryVideoThumbnail: file(
      relativePath: { eq: "PatientStoriesAndResources/rosalines_story_video_thumbnail.webp" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 460, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jamesStoryVideoThumbnail: file(
      relativePath: { eq: "PatientStoriesAndResources/james-story-video-thumbnail.png" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 460, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    davidsStoryVideoThumbnail: file(
      relativePath: { eq: "PatientStoriesAndResources/davids_story_video_thumbnail.webp" }
    ) {
      name
      childImageSharp {
        fluid(maxWidth: 820, maxHeight: 460, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default PatientStoriesAndResources;
